import React from 'react';
import KuroLink from '../components/common/KuroLink';

const Keyboards = () => {
    return (
        <div className="keyboards txt-light-grey">
            <p className="breadcrumb txt-light-grey"><KuroLink to="/accessories">Accessories</KuroLink> &gt; Keyboards</p>
            <div className="page-title">
                <h1>Keyboards</h1>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Basic Keyboards</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/keyboards/basic">View All</KuroLink>
                </div>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Membrane Keyboards</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/keyboards/membrane">View All</KuroLink>
                </div>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Mechanical Keyboards</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/keyboards/mechanical">View All</KuroLink>
                </div>
            </div>
        </div>
    )
}

export default Keyboards;