import { Switch, Route } from 'react-router';
import Home from '../../pages/Home';
import Users from '../../pages/Users';
import UserDetails from '../../pages/UserDetails';
import UserOrders from '../../pages/UserOrders';
import Orders from '../../pages/Orders';
import Products from '../../pages/Products';
import CoreComponents from '../../pages/CoreComponents';
import CoreComponent from '../../pages/CoreComponent';
import PreBuilts from '../../pages/PreBuilts';
import Builds from '../../pages/Builds';
import Monitors from '../../pages/Monitors';
import Accessories from '../../pages/Accessories';
import Keyboards from '../../pages/Keyboards';
import KeyboardAccessories from '../../pages/KeyboardAccessories';
import BasicKeyboards from '../../pages/BasicKeyboards';
import MembraneKeyboards from '../../pages/MembraneKeyboards';
import MechanicalKeyboards from '../../pages/MechanicalKeyboards';
import Mouse from '../../pages/Mouse';
import MouseAccessories from '../../pages/MouseAccessories';
import KeyboardMouse from '../../pages/KeyboardMouse';
import StreamingGear from '../../pages/StreamingGear';
import ExternalDevices from '../../pages/ExternalDevices';
import NetworkingGear from '../../pages/NetworkingGear';
import CaptureCards from '../../pages/CaptureCards';
import GamingChair from '../../pages/GamingChair';
import StreamingMic from '../../pages/StreamingMic';
import Webcam from '../../pages/Webcam';
import GamingHeadset from '../../pages/GamingHeadset';

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/users/user-list" component={Users} />
            <Route exact path="/users/:userId" component={UserDetails} />
            <Route exact path="/orders/:userId" component={UserOrders} />
            <Route exact path="/orders" component={Orders} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/pc-components/core" component={CoreComponents} />
            <Route exact path="/pc-components/core/:compType" component={CoreComponent} />
            <Route exact path="/pre-builts" component={PreBuilts} />
            <Route exact path="/pre-builts/:buildSeries" component={Builds} />
            <Route exact path="/pc-components/monitors" component={Monitors} />
            <Route exact path="/accessories" component={Accessories} />
            <Route exact path="/pc-components/external-devices" component={ExternalDevices} />
            <Route exact path="/pc-components/networking-gear" component={NetworkingGear} />
            <Route exact path="/accessories/keyboards" component={Keyboards} />
            <Route exact path="/accessories/keyboard-accessories" component={KeyboardAccessories} />
            <Route exact path="/accessories/keyboards/basic" component={BasicKeyboards} />
            <Route exact path="/accessories/keyboards/membrane" component={MembraneKeyboards} />
            <Route exact path="/accessories/keyboards/mechanical" component={MechanicalKeyboards} />
            <Route exact path="/accessories/mouse" component={Mouse} />
            <Route exact path="/accessories/mouse-accessories" component={MouseAccessories} />
            <Route exact path="/accessories/keyboard-mouse-combo" component={KeyboardMouse} />
            <Route exact path="/accessories/streaming-gear" component={StreamingGear} />
            <Route exact path="/accessories/streaming-gear/capture-card" component={CaptureCards} />
            <Route exact path="/accessories/streaming-gear/gaming-chair" component={GamingChair} />
            <Route exact path="/accessories/streaming-gear/streaming-mic" component={StreamingMic} />
            <Route exact path="/accessories/streaming-gear/webcam" component={Webcam} />
            <Route exact path="/accessories/streaming-gear/gaming-headset" component={GamingHeadset} />
        </Switch>
    )
}

export default Routes;