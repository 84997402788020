import React, {useState, useEffect} from 'react'
import axios from 'axios'
import EditableProd from '../components/EditableProd'
import KuroLink from '../components/common/KuroLink'
import '../styles/products.css'

const Monitors = () => {

    const [monitorData, setMonitorData] = useState(null)

    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/monitors').then(res => {
            setMonitorData(res.data)
        })
    }, [])

    return (
        <div className="products">
            <p className="breadcrumb txt-light-grey"><KuroLink to="/pc-components">Components</KuroLink> &gt; Monitors</p>
            <div className="page-title">
                <h1>Monitors</h1>
            </div>
            {monitorData !== null && monitorData.map((prod) =>
                <EditableProd prod={prod} collection="monitors" />
            )}
        </div>
    )
}

export default Monitors