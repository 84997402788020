import React, {useState, useEffect} from 'react';
import axios from 'axios';
import EditableProd from '../components/EditableProd';
import KuroLink from '../components/common/KuroLink';
import '../styles/products.css';

const KeyboardMouse = () => {

    const [prodData, setProdData] = useState(null);

    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/accessories?type=kbdmouse').then(res => {
            setProdData(res.data);
        });
    }, []);

    return (
        <div className="products">
            <p className="breadcrumb txt-light-grey"><KuroLink to="/accessories">Accessories</KuroLink> &gt; Keyboard and Mouse Combo</p>
            <div className="page-title">
                <h1>Keyboard and Mouse Combo</h1>
            </div>
            {prodData !== null && prodData.map((prod) =>
                <EditableProd prod={prod} collection="accessories" />
            )}
        </div>
    )
}

export default KeyboardMouse;