import React, {useEffect} from 'react';
import axios from 'axios';

const EditableOrder = ({ order }) => {

    const orderInfo = order;
    const orderData = order;
    
    useEffect(() => {
        for (const item in order) {
            if (document.querySelectorAll("#" + item).length > 0 && order[item] !== null) {
                document.querySelector("#" + item).value = order[item];
            }
        }
    }, [order]);

    const categoryHandler = (category) => {
        switch (category) {
            case "component":
                return "/p/"
            case "monitor":
                return "/m/"
            case "build":
                return "/b/"
            case "accessory":
                return "/a/"
            case "networking":
                return "/n/"
            case "external":
                return "/e/"
            default:
                return "/p/"
        }
    };

    const submitHandler = () => {
        const orderid = order.orderid;
        const order_status = document.querySelectorAll(".order.order_" + orderid + " .order_status").length > 0 ? document.querySelector(".order.order_" + orderid + " .order_status").value : "";
        const order_confirmed = document.querySelectorAll(".order.order_" + orderid + " .order_confirmed").length > 0 ? document.querySelector(".order.order_" + orderid + " .order_confirmed").checked : false;
        const order_pc_build_start = document.querySelectorAll(".order.order_" + orderid + " .order_pc_build_start").length > 0 ? document.querySelector(".order.order_" + orderid + " .order_pc_build_start").checked : false;
        const order_pc_build_end = document.querySelectorAll(".order.order_" + orderid + " .order_pc_build_end").length > 0 ? document.querySelector(".order.order_" + orderid + " .order_pc_build_end").checked : false;
        const order_pc_test_start = document.querySelectorAll(".order.order_" + orderid + " .order_pc_test_start").length > 0 ? document.querySelector(".order.order_" + orderid + " .order_pc_test_start").checked : false;
        const order_pc_test_end = document.querySelectorAll(".order.order_" + orderid + " .order_pc_test_end").length > 0 ? document.querySelector(".order.order_" + orderid + " .order_pc_test_end").checked : false;
        const order_packed = document.querySelectorAll(".order.order_" + orderid + " .order_packed").length > 0 ? document.querySelector(".order.order_" + orderid + " .order_packed").checked : false;
        const order_shipped = document.querySelectorAll(".order.order_" + orderid + " .order_shipped").length > 0 ? document.querySelector(".order.order_" + orderid + " .order_shipped").checked : false;
        const order_delivered = document.querySelectorAll(".order.order_" + orderid + " .order_delivered").length > 0 ? document.querySelector(".order.order_" + orderid + " .order_delivered").checked : false;
        const shp_agency = document.querySelectorAll(".order.order_" + orderid + " .shp_agency").length > 0 ? document.querySelector(".order.order_" + orderid + " .shp_agency").value : "";
        const shp_url = document.querySelectorAll(".order.order_" + orderid + " .shp_url").length > 0 ? document.querySelector(".order.order_" + orderid + " .shp_url").value : "";
        const shp_awb = document.querySelectorAll(".order.order_" + orderid + " .shp_awb").length > 0 ? document.querySelector(".order.order_" + orderid + " .shp_awb").value : "";
        const shp_status = document.querySelectorAll(".order.order_" + orderid + " .shp_status").length > 0 ? document.querySelector(".order.order_" + orderid + " .shp_status").value : "";

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        
        const body = JSON.stringify({ orderid, order_status, order_confirmed, order_pc_build_start, order_pc_build_end, order_pc_test_start, order_pc_test_end, order_packed, order_shipped, order_delivered, shp_agency, shp_url, shp_awb, shp_status });
        axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/orders?phone=9492540571', body, config);
    };

    return (
        <div className={"order order_" + order.orderid }>
            <table cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>orderid</td>
                        <td>order_total</td>
                        <td>order_status</td>
                        <td>order_created</td>
                        <td>order_placed</td>
                        <td>pkg_fees</td>
                        <td>build_fees</td>
                        <td>shp_fees</td>
                        <td>kuro_discount</td>
                        <td>tax_state_code</td>
                        <td>tax_total</td>
                        <td>shp_addressid</td>
                        <td>bill_addressid</td>
                        <td>temp_reference</td>
                        <td>pay_reference</td>
                        <td rowSpan="3"><button className="submit" onClick={submitHandler}>Submit</button></td>
                    </tr>
                    <tr>
                        <td rowSpan="2">{orderInfo.orderid.toUpperCase()}</td>
                        <td rowSpan="2">{orderInfo.order_total}</td>
                        <td>{orderInfo.order_status}</td>
                        <td>{orderInfo.order_created}</td>
                        <td>{orderInfo.order_placed}</td>
                        <td>{orderInfo.pkg_fees}</td>
                        <td>{orderInfo.build_fees}</td>
                        <td>{orderInfo.shp_fees}</td>
                        <td>{orderInfo.kuro_discount}</td>
                        <td>{orderInfo.tax_state_code}</td>
                        <td>{orderInfo.tax_total}</td>
                        <td rowSpan="2">{orderInfo.shp_address.map((item, i) => 
                            item !== "" && 
                            <>
                            {item}<br/>
                            </>
                        )}</td>
                        <td rowSpan="2">{orderInfo.bill_address.map((item, i) => 
                            item !== "" && 
                            <>
                            {item}<br/>
                            </>
                        )}</td>
                        <td rowSpan="2">{orderInfo.temp_reference}</td>
                        <td rowSpan="2">{orderInfo.pay_reference}</td>
                    </tr>
                    <tr>
                        <td>
                            <select className="order_status">
                                <option value="Payment Pending">Payment Pending</option>
                                <option value="Order Placed">Order Placed</option>
                                <option value="Order Confirmed">Order Confirmed</option>
                                <option value="Build Started">Build Started</option>
                                <option value="Build Completed">Build Completed</option>
                                <option value="Testing Started">Testing Started</option>
                                <option value="Testing Completed">Testing Completed</option>
                                <option value="Packed">Packed</option>
                                <option value="Shipped">Shipped</option>
                                <option value="Delivered">Delivered</option>
                            </select>
                        </td>
                        <td>{orderData.order_created}</td>
                        <td>{orderData.order_placed}</td>
                        <td>{orderData.pkg_fees}</td>
                        <td>{orderData.build_fees}</td>
                        <td>{orderData.shp_fees}</td>
                        <td>{orderData.kuro_discount}</td>
                        <td>{orderData.tax_state_code}</td>
                        <td>{orderData.tax_total}</td>
                    </tr>
                </tbody>
            </table>
            <table cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>order_confirmed</td>
                        <td>order_pc_build_start</td>
                        <td>order_pc_build_end</td>
                        <td>order_pc_test_start</td>
                        <td>order_pc_test_end</td>
                        <td>order_packed</td>
                        <td>order_shipped</td>
                        <td>order_delivered</td>
                        <td>shp_agency</td>
                        <td>shp_url</td>
                        <td>shp_awb</td>
                        <td>shp_status</td>
                    </tr>
                    <tr>
                        {order.order_confirmed === null || order.order_confirmed === "" ? (<td><input type="checkbox" className="order_confirmed" /></td>) : (<td>{order.order_confirmed}</td>)}
                        {order.order_pc_build_start === null || order.order_pc_build_start === "" ? (<td><input type="checkbox" className="order_pc_build_start" /></td>) : (<td>{order.order_pc_build_start}</td>)}
                        {order.order_pc_build_end === null || order.order_pc_build_end === "" ? (<td><input type="checkbox" className="order_pc_build_end" /></td>) : (<td>{order.order_pc_build_end}</td>)}
                        {order.order_pc_test_start === null || order.order_pc_test_start === "" ? (<td><input type="checkbox" className="order_pc_test_start" /></td>) : (<td>{order.order_pc_test_start}</td>)}
                        {order.order_pc_test_end === null || order.order_pc_test_end === "" ? (<td><input type="checkbox" className="order_pc_test_end" /></td>) : (<td>{order.order_pc_test_end}</td>)}
                        {order.order_packed === null || order.order_packed === "" ? (<td><input type="checkbox" className="order_packed" /></td>) : (<td>{order.order_packed}</td>)}
                        {order.order_shipped === null || order.order_shipped === "" ? (<td><input type="checkbox" className="order_shipped" /></td>) : (<td>{order.order_shipped}</td>)}
                        {order.order_delivered === null || order.order_delivered === "" ? (<td><input type="checkbox" className="order_delivered" /></td>) : (<td>{order.order_delivered}</td>)}
                        {order.shp_agency === null || order.shp_agency === "" ? (
                        <td>
                            <select className="shp_agency">
                                <option value="">Select One</option>
                                <option value="Delhivery">Delhivery</option>
                                <option value="BlueDart">BlueDart</option>
                                <option value="FedEx">FedEx</option>
                                <option value="DTDC">DTDC</option>
                                <option value="Xpressbees">Xpressbees</option>
                                <option value="Amazon Shipping">Amazon Shipping</option>
                                <option value="DHL">DHL</option>
                                <option value="Ecom Express">Ecom Express</option>
                                <option value="Dunzo">Dunzo</option>
                                <option value="Porter">Porter</option>
                                <option value="Rapido">Rapido</option>
                                <option value="Gati">Gati</option>
                                <option value="Shadowfax">Shadowfax</option>
                                <option value="WeFast">WeFast</option>
                                <option value="DotZot">DotZot</option>
                                <option value="Ekart Logistics">Ekart Logistics</option>
                                <option value="Aramex International">Aramex International</option>
                                <option value="Kerry Indev Express">Kerry Indev Express</option>
                            </select>
                        </td>
                        ) : (
                        <td>{order.shp_agency}</td>
                        )}
                        {order.shp_url === null || order.shp_url === "" ? (<td><input className="shp_url" /></td>) : (<td>{order.shp_url}</td>)}
                        {order.shp_awb === null || order.shp_awb === "" ? (<td><input className="shp_awb" /></td>) : (<td>{order.shp_awb}</td>)}
                        {order.shp_status === null || order.shp_status === "" ? (<td><input className="shp_status" /></td>) : (<td>{order.shp_status}</td>)}
                    </tr>
                </tbody>
            </table>
            <table cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>ID</td>
                        <td>Productid</td>
                        <td>Title</td>
                        <td>Category</td>
                        <td>Price</td>
                        <td>Quantity</td>
                    </tr>
                    {order.orderitems.map((item, i) =>
                    <tr>
                        <td>{item.id}</td>
                        <td>{item.productid}</td>
                        <td><a href={"https://kurogaming.com" + categoryHandler(item.category) + item.prod_url} target="_blank" rel="noreferrer">{item.title}</a></td>
                        <td>{item.category}</td>
                        <td>{item.price}</td>
                        <td>{item.quantity}</td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default EditableOrder;