import React, {useState, useEffect} from 'react';
import axios from 'axios';
import EditableProd from '../components/EditableProd';
import KuroLink from '../components/common/KuroLink';
import '../styles/products.css';

const Webcam = () => {

    const [prodData, setProdData] = useState(null);

    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/accessories?type=streaming-gear&category=webcam').then(res => {
            setProdData(res.data);
        });
    }, []);

    return (
        <div className="products">
            <p className="breadcrumb txt-light-grey"><KuroLink to="/accessories">Accessories</KuroLink> &gt; <KuroLink to="/accessories/streaming-gear">Streaming Gear</KuroLink> &gt; Webcam</p>
            <div className="page-title">
                <h1>Webcam</h1>
            </div>
            {prodData !== null && prodData.map((prod) =>
                <EditableProd prod={prod} collection="accessories" />
            )}
        </div>
    )
}

export default Webcam;