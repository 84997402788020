import React, { useEffect, Fragment } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { isMobile } from "react-device-detect"
import Routes from './components/common/Routes'
import ScrollToTop from './components/common/ScrollToTop'
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import { Provider } from 'react-redux'
import { getComponents, getMonitors, getAccessories, getExternal, getNetworking, getPresets } from './actions/products'
import store from './store'
import './App.css'
import withClearCache from "./ClearCache"

const MainApp = () => {

    useEffect(() => {
        if (isMobile) {
            document.body.classList.add("touch")
        } else {
            document.body.classList.add("no-touch")
        }
        store.dispatch(getComponents())
        store.dispatch(getMonitors())
        store.dispatch(getAccessories())
        store.dispatch(getExternal())
        store.dispatch(getNetworking())
        store.dispatch(getExternal())
        store.dispatch(getPresets())
    }, [])

    return (
        <Provider store={store}>
            <Router>
                <Fragment>
                    <ScrollToTop />
                    <Header/>
                    <div className="main">
                        <Routes />
                    </div>
                    <Footer/>
                </Fragment>
            </Router>
        </Provider>
    );
}

const ClearCacheComponent = withClearCache(MainApp)

function App () {
    return <ClearCacheComponent />
}

export default App