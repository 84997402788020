import React, {useState} from 'react';
import axios from 'axios';
import EditableProd from '../components/EditableProd';
import EditableBuild from '../components/EditableBuild';

const ProductFinder = ({ type }) => {

    const [prodData, setProdData] = useState(null);
    const [collection, setCollection] = useState("prebuilds");

    const searchHandler = (e) => {
        setProdData(null);
        const type = e.target.closest('.search-wrap').querySelector('.type').value;
        let prodvalue = e.target.closest('.search-wrap').querySelector('.prodvalue').value;
        if (type === "prod_url") {
            prodvalue = prodvalue.substring(prodvalue.lastIndexOf('/') + 1);
        }
        setCollection(e.target.closest('.search-wrap').querySelector('.collection').value);
        if (prodvalue !== "") {
            axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/' + e.target.closest('.search-wrap').querySelector('.collection').value + '?' + type + '=' + prodvalue).then(res => {
                setProdData(res.data);
            });
        }
    };

    return (
        <div className="prod-search txt-light-grey">
            <div className="search-wrap">
                <select className="type">
                    <option value="prod_url">ProductUrl</option>
                    <option value="productid">ProductId</option>
                </select>
                <select className="collection">
                    <option value="prebuilds">Builds</option>
                    <option value="components">Components</option>
                    <option value="monitors">Monitors</option>
                    <option value="accessories">Accessories</option>
                    <option value="external">External Devices</option>
                    <option value="networking">Networking</option>
                </select>
                <input className="prodvalue" />
                <button onClick={searchHandler}>Search</button>
            </div>
            <div className="search-results">
            {prodData === null || prodData.length === 0 ? (
                <p>No results to show</p>
            ) : (
                <>
                {collection === "prebuilds" ? (
                    <EditableBuild prod={prodData[0]} type={type} />
                ) : (
                    <EditableProd prod={prodData[0]} collection={collection} />
                )}
                </>
            )}
            </div>
        </div>
    )
}

export default ProductFinder;