import React from 'react';
import KuroLink from '../components/common/KuroLink';
import { CPUIcon, MOBIcon, GPUIcon, RAMIcon, SSDIcon, HDDIcon, PSUIcon, CoolerIcon, CaseIcon, FanIcon } from '../data/SvgData';
import '../styles/core-comps.css';

const CoreComponents = () => {

    return (
        <div className="core-comps">
            <p className="breadcrumb txt-light-grey"><KuroLink to="/pc-components">Components</KuroLink> &gt; Core</p>
            <div className="page-title">
                <h1>Core Components</h1>
            </div>
            <div className="comp-wrap">
                <div className="components">
                    <KuroLink to="/pc-components/core/cpu">
                        <div className="comp">
                            <div className="med">
                                <CPUIcon/>
                            </div>
                            <div className="cnt">
                                <h2 className="pri-6">Processor</h2>
                            </div>
                        </div>
                    </KuroLink>
                    <KuroLink to="/pc-components/core/mob">
                        <div className="comp">
                            <div className="med">
                                <MOBIcon/>
                            </div>
                            <div className="cnt">
                                <h2 className="pri-6">Motherboard</h2>
                            </div>
                        </div>
                    </KuroLink>
                    <KuroLink to="/pc-components/core/gpu">
                        <div className="comp">
                            <div className="med">
                                <GPUIcon/>
                            </div>
                            <div className="cnt">
                                <h2 className="pri-6">Graphics Card</h2>
                            </div>
                        </div>
                    </KuroLink>
                    <KuroLink to="/pc-components/core/ram">
                        <div className="comp">
                            <div className="med">
                                <RAMIcon/>
                            </div>
                            <div className="cnt">
                                <h2 className="pri-6">Memory (RAM)</h2>
                            </div>
                        </div>
                    </KuroLink>
                    <KuroLink to="/pc-components/core/psu">
                        <div className="comp">
                            <div className="med">
                                <PSUIcon/>
                            </div>
                            <div className="cnt">
                                <h2 className="pri-6">Power Supply Unit</h2>
                            </div>
                        </div>
                    </KuroLink>
                    <KuroLink to="/pc-components/core/ssd">
                        <div className="comp">
                            <div className="med">
                                <SSDIcon/>
                            </div>
                            <div className="cnt">
                                <h2 className="pri-6">SSD Storage</h2>
                            </div>
                        </div>
                    </KuroLink>
                    <KuroLink to="/pc-components/core/hdd">
                        <div className="comp">
                            <div className="med">
                                <HDDIcon/>
                            </div>
                            <div className="cnt">
                                <h2 className="pri-6">HDD Storage</h2>
                            </div>
                        </div>
                    </KuroLink>
                    <KuroLink to="/pc-components/core/cooler">
                        <div className="comp">
                            <div className="med">
                                <CoolerIcon/>
                            </div>
                            <div className="cnt">
                                <h2 className="pri-6">CPU Cooler</h2>
                            </div>
                        </div>
                    </KuroLink>
                    <KuroLink to="/pc-components/core/tower">
                        <div className="comp">
                            <div className="med">
                                <CaseIcon/>
                            </div>
                            <div className="cnt">
                                <h2 className="pri-6">Case</h2>
                            </div>
                        </div>
                    </KuroLink>
                    <KuroLink to="/pc-components/core/fan">
                        <div className="comp">
                            <div className="med">
                                <FanIcon/>
                            </div>
                            <div className="cnt">
                                <h2 className="pri-6">FANS</h2>
                            </div>
                        </div>
                    </KuroLink>
                </div>
            </div>
        </div>
    )
}

export default CoreComponents;