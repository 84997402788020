import React, {useState, useEffect} from 'react'
import axios from 'axios'
import EditableOrder from '../components/EditableOrder'
import '../styles/user.css'

const UserDetails = ({ match }) => {

    const { params: { userId } } = match

    const [userData, setUserData] = useState(null)

    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/userdetails?phone=9492540571&userid=' + userId).then(res => {
            setUserData(res.data);
        })
    }, [])

    const categoryHandler = (category) => {
        switch (category) {
            case "component":
                return "/p/"
            case "monitor":
                return "/m/"
            case "build":
                return "/b/"
            case "accessory":
                return "/a/"
            case "networking":
                return "/n/"
            case "external":
                return "/e/"
            default:
                return "/p/"
        }
    }

    return (
        <div className="users txt-light-grey">
        {userData !== null && userData.map((item, i) =>
            <div>
                <h3>Profile</h3>
                <table cellPadding="0" cellSpacing="0">
                    <tr>
                        <td>Userid</td>
                        <td>Name</td>
                        <td>Phone</td>
                        <td>Email</td>
                        <td>Username</td>
                        <td>Last Login</td>
                    </tr>
                    <tr>
                        <td>{item.profile.userid}</td>
                        <td>{item.profile.name}</td>
                        <td>{item.profile.phone}</td>
                        <td>{item.profile.email}</td>
                        <td>{item.profile.username}</td>
                        <td>{item.profile.last_login}</td>
                    </tr>
                </table>
                <h3>Addresses</h3>
                <table cellPadding="0" cellSpacing="0">
                    <tr>
                        <td>Address Id</td>
                        <td>Full Name</td>
                        <td>Phone</td>
                        <td>Alt Phone</td>
                        <td>Pincode</td>
                        <td>Addressline1</td>
                        <td>Addressline2</td>
                        <td>Landmark</td>
                        <td>City</td>
                        <td>State</td>
                        <td>GSTIN</td>
                        <td>PAN</td>
                    </tr>
                    {item.addresses.map((address) =>
                    <tr>
                        <td>{address.addressid}</td>
                        <td>{address.fullname}</td>
                        <td>{address.phone}</td>
                        <td>{address.altphone}</td>
                        <td>{address.pincode}</td>
                        <td>{address.addressline1}</td>
                        <td>{address.addressline2}</td>
                        <td>{address.landmark}</td>
                        <td>{address.city}</td>
                        <td>{address.state}</td>
                        <td>{address.gstin}</td>
                        <td>{address.pan}</td>
                    </tr>
                    )}
                </table>
                <h3>Cart</h3>
                <table cellPadding="0" cellSpacing="0">
                    <tr>
                        <td>Cart Id</td>
                        <td>Productid</td>
                        <td>Category</td>
                        <td>Quantity</td>
                        <td>Title</td>
                        <td>Status</td>
                        <td>Price</td>
                    </tr>
                    {item.cart.map((prod) =>
                    <tr>
                        <td>{prod.cartid}</td>
                        <td><a href={"https://kurogaming.com" + categoryHandler(prod.category) + prod.prod_url} target="_blank" rel="noreferrer">{prod.productid}</a></td>
                        <td>{prod.category}</td>
                        <td>{prod.quantity}</td>
                        <td>{prod.title}</td>
                        <td>{prod.status}</td>
                        <td>{prod.price}</td>
                    </tr>
                    )}
                </table>
                <h3>Orders</h3>
                <div className="orders-list">
                {item.orders.map((order) =>
                    <EditableOrder order={order} />
                )}
                </div>
            </div>
        )}
        </div>
    )
}

export default UserDetails