import React from 'react';
import KuroLink from '../components/common/KuroLink';

const PreBuilts = () => {
    return (
        <div className="pre-builts">
            <div className="page-title">
                <h1>Pre Builts</h1>
            </div>
            <div className="builds">
                <div className="cnt">
                    <h2>Gaming Desktops</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/pre-builts/gaming-desktops">View All</KuroLink>
                </div>
            </div>
            <div className="builds">
                <div className="cnt">
                    <h2>Work Stations</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/pre-builts/work-stations">View All</KuroLink>
                </div>
            </div>
            <div className="builds">
                <div className="cnt">
                    <h2>Home and Office PCs</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/pre-builts/home-and-office-pc">View All</KuroLink>
                </div>
            </div>
            <div className="builds">
                <div className="cnt">
                    <h2>Small Form Factor Desktops</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/pre-builts/sff-desktops">View All</KuroLink>
                </div>
            </div>
        </div>
    )
}

export default PreBuilts;