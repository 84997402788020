import React, {useState, useEffect} from 'react';
import axios from 'axios';
import EditableProd from '../components/EditableProd';
import '../styles/products.css';

const CoreComponent = ({ match }) => {

    const { params: { compType } } = match;
    const [compData, setCompData] = useState(null);

    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/components?type=' + compType).then(res => {
            setCompData(res.data);
        });
    }, [compType]);

    return (
        <div className="products">
            {compData !== null && compData.map((prod) =>
                <EditableProd prod={prod} collection="components" />
            )}
        </div>
    )
}

export default CoreComponent;