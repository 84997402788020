import React from 'react'
import '../styles/products.css'
import '../styles/tabs.css'
import ApproveProducts from '../components/ApproveProducts'

const Products = () => {

    return (
        <div className="products">
            <h2 className="txt-light-grey">Approve Products</h2>
            <ApproveProducts />
        </div>
    )
}

export default Products