import React from 'react';
import KuroLink from '../components/common/KuroLink';

const Accessories = () => {
    return (
        <div className="accessories txt-light">
            <div className="page-title">
                <h1>Accessories</h1>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Keyboards</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/keyboards">View All</KuroLink>
                </div>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Keyboard Accessories</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/keyboard-accessories">View All</KuroLink>
                </div>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Mouse</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/mouse">View All</KuroLink>
                </div>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Mouse Accessories</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/mouse-accessories">View All</KuroLink>
                </div>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Keyboard Mouse Combo</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/keyboard-mouse-combo">View All</KuroLink>
                </div>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Streaming Gear</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/streaming-gear">View All</KuroLink>
                </div>
            </div>
        </div>
    )
}

export default Accessories;