import React from 'react';
import KuroLink from '../common/KuroLink';
import '../../styles/footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className="legal-footer">
                <div className="footer-links mx-width">
                    <div className="ft-cnt">
                        <div className="rights-content">
                            <p>&copy; 2020 <KuroLink to="/">Kuro Gaming</KuroLink>. All Rights Reserved.</p>
                        </div>
                        <div className="logo">
                            <KuroLink to="/"><svg viewBox="200 250 1300 700"><title>Kuro Gaming</title><polygon fill="#ee2d30" points="850.68 688.03 885.99 611.01 1180.3 611.01 1264.86 556.53 838.61 553.03 850.68 688.03"/><polygon fill="#ee2d30" points="433.12 715.79 274.77 541.21 753.1 54.3 1029.11 54.3 549.72 541.21 706.98 712.95 433.12 715.79"/><polygon fill="#ee2d30" points="809.78 831.25 850.75 879 1099.35 563.66 850.68 563.95 850.68 688.03 756.3 541.21 1426.01 541.21 850.75 1194.56 532.77 830.61 809.78 831.25"/></svg></KuroLink>
                        </div>
                    </div>
                    <div className="disclaimer">
                        <p>Disclaimer: All third party trademarks (including logos and icons) referenced remain the property of their respective owners. Unless specifically identified as such, use of third party trademarks does not indicate any relationship, sponsorship, or endorsement between Kuro Gaming and the owners of these trademarks. All references by Kuro Gaming to third party trademarks are to identify the corresponding third party goods and/or services and intended to constitute nominative fair use under applicable trademark laws. Prices and specifications on KuroGaming.com are subject to change without notice. Kuro Gaming or any of its affiliates or parent organisations are not responsible for any typo, photograph, or program errors, and reserves the right to cancel any incorrect orders. System pictures (with or without optional accessories) that are displayed are for representative purposes only and the actual builds or Systems would be based on the respective chosen specifications or builds.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;