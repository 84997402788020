import React, {useState, useEffect} from 'react';
import axios from 'axios';
import EditableOrder from '../components/EditableOrder';
import '../styles/orders.css';

const UserOrders = ({ match }) => {

    const { params: { userId } } = match;

    const [ordersData, setOrdersData] = useState(null);

    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/orders?phone=9492540571&userid=' + userId).then(res => {
            setOrdersData(res.data);
        });
    }, []);

    return (
        <div className="orders txt-light-grey">
            <h3>Orders</h3>
            <div className="orders-list">
            {ordersData !== null && ordersData.map((order, i) =>
                <EditableOrder key={i} order={order} />
            )}
            </div>
        </div>
    )
}

export default UserOrders;