import React, {useState, useEffect} from 'react';
import axios from 'axios';
import KuroLink from '../components/common/KuroLink';
import '../styles/user.css';

const Users = () => {

    const [userData, setuserData] = useState(null);

    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/userlist?phone=9492540571').then(res => {
            setuserData(res.data);
        });
    }, []);

    return (
        <div className="users txt-light-grey">
            <h2>User List</h2>
            <table cellPadding="0" cellSpacing="0">
                <tr>
                    <td>Userid</td>
                    <td>Name</td>
                    <td>Phone</td>
                    <td>Email</td>
                    <td>Username</td>
                    <td>Last Login</td>
                    <td>Orders</td>
                </tr>
            {userData !== null && userData.map((user, i) =>
                <tr key={i}>
                    <td><KuroLink to={'/users/' + user.userid}>{user.userid}</KuroLink></td>
                    <td>{user.name}</td>
                    <td>{user.phone}</td>
                    <td>{user.email}</td>
                    <td>{user.username}</td>
                    <td>{user.last_login}</td>
                    <td><KuroLink to={{pathname: '/orders/' + user.userid, state: {userid: user.userid}}}>{user.orders}</KuroLink></td>
                </tr>
            )}
            </table>
        </div>
    )
}

export default Users;