import React from 'react';
import ProductFinder from './ProductFinder';
import '../styles/products.css';
import '../styles/tabs.css';
import '../styles/table.css';

const Home = () => {

    return (
        <div className="home">
            <h2 className="txt-light-grey">Product Finder</h2>
            <ProductFinder type="update" />
        </div>
    )
}

export default Home;