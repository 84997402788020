import React, {useState, useEffect} from 'react'
import axios from 'axios'
import KuroLink from './common/KuroLink'

const ApproveProducts = () => {

    const [prodData, setProdData] = useState(null)
    
    useEffect(() => {
        gettempproducts()
    }, [])

    const gettempproducts = () => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/tempproducts').then(res => {
            setProdData(res.data)
        })
    }

    const updateFlag = (e, field) => {
        let tempProd = [...prodData]
        tempProd[0][field] = e.target.checked
        setProdData(tempProd)
    }

    const approveHandler = (index) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const body = prodData[index]

        axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/addproduct', body, config)
        .then((res) => {
            gettempproducts()
        })
    }

    const deleteHandler = (productid) => {
        axios.delete(process.env.REACT_APP_KG_API_URL + 'kuroadmin/addproduct?productid=' + productid)
        .then((res) => {
            gettempproducts()
        })
    }

    return (
        <div className="prod prod-list">
        {prodData !== null && prodData.map((prod, i) =>
            <table key={i} cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>Product Id</td>
                        <td>Product Url</td>
                        <td>Title</td>
                        <td>Collection</td>
                        <td>Type</td>
                        <td>Category</td>
                        <td>Delete Flag</td>
                        <td>Active</td>
                        <td rowSpan="2"><button id="submit" onClick={() => approveHandler(i)}>Approve</button></td>
                        <td rowSpan="2"><button id="delete" onClick={() => deleteHandler(prod.productid)}>Delete</button></td>
                    </tr>
                    <tr>
                        <td><KuroLink to={"/product/" + prod.prod_url}>{prod.productid}</KuroLink></td>
                        <td>{prod.prod_url}</td>
                        <td>{prod.title}</td>
                        <td>{prod.collection}</td>
                        <td>{prod.type}</td>
                        <td>{prod.category}</td>
                        <td><input type="checkbox" checked={prod.delete_flag && "checked"} onChange={(e) => updateFlag(e, "delete_flag")} /></td>
                        <td><input type="checkbox" checked={prod.active && "checked"} onChange={(e) => updateFlag(e, "active")} /></td>
                    </tr>
                </tbody>
            </table>
        )}
        </div>
    )
}

export default ApproveProducts