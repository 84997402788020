import React from 'react';
import KuroLink from '../components/common/KuroLink';

const StreamingGear = () => {
    return (
        <div className="streaming-gear txt-light-grey">
            <p className="breadcrumb txt-light-grey"><KuroLink to="/accessories">Accessories</KuroLink> &gt; Streaming Gear</p>
            <div className="page-title">
                <h1>Streaming Gear</h1>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Capture Card</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/streaming-gear/capture-card">View All</KuroLink>
                </div>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Gaming Chair</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/streaming-gear/gaming-chair">View All</KuroLink>
                </div>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Streaming Mic</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/streaming-gear/streaming-mic">View All</KuroLink>
                </div>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Webcam</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/streaming-gear/webcam">View All</KuroLink>
                </div>
            </div>
            <div className="category">
                <div className="cnt">
                    <h2>Gaming Headset</h2>
                </div>
                <div className="slides">
                    <KuroLink to="/accessories/streaming-gear/gaming-headset">View All</KuroLink>
                </div>
            </div>
        </div>
    )
}

export default StreamingGear;