import React, {useState, useEffect} from 'react';
import axios from 'axios';
import EditableProd from '../components/EditableProd';
import '../styles/products.css';

const MechanicalKeyboards = () => {

    const [prodData, setProdData] = useState(null);

    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/accessories?type=keyboard&category=mechanical').then(res => {
            setProdData(res.data);
        });
    }, []);

    return (
        <div className="products">
            {prodData !== null && prodData.map((prod) =>
                <EditableProd prod={prod} collection="accessories" />
            )}
        </div>
    )
}

export default MechanicalKeyboards;