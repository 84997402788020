import React, {useState, useEffect} from 'react';
import axios from 'axios';
import EditableProd from '../components/EditableProd';
import KuroLink from '../components/common/KuroLink';
import '../styles/products.css';

const ExternalDevices = () => {

    const [externalData, setExternalData] = useState(null);

    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/external?type=hdd').then(res => {
            setExternalData(res.data);
        });
    }, []);

    return (
        <div className="external-devices">
            <p className="breadcrumb txt-light-grey"><KuroLink to="/pc-components">Components</KuroLink> &gt; External Devices</p>
            <div className="page-title">
                <h1>External Devices</h1>
            </div>
            {externalData !== null && externalData.map((prod) =>
                <EditableProd prod={prod} collection="external" />
            )}
        </div>
    )
}

export default ExternalDevices;