import React, {useState, useEffect} from 'react'
import axios from 'axios'
import EditableOrder from '../components/EditableOrder'
import '../styles/user.css'

const Orders = () => {

    const [orderData, setOrderData] = useState(null)

    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/orders?phone=9492540571').then(res => {
            setOrderData(res.data)
        })
    }, [])

    return (
        <div className="orders txt-light-grey">
            {orderData !== null && orderData.map((order) =>
                <EditableOrder order={order} />
            )}
        </div>
    )
}

export default Orders