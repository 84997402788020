import React, {useState, useEffect} from 'react'
import axios from 'axios'
import EditableBuild from '../components/EditableBuild'
import '../styles/products.css'

const Builds = ({ match }) => {

    const { params: { buildSeries } } = match
    const [buildData, setBuildData] = useState(null)

    useEffect(() => {
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/prebuilds?series=' + buildSeries).then(res => {
            setBuildData(res.data)
        });
    }, [buildSeries])

    return (
        <div className="products">
            {buildData !== null && buildData.map((prod) =>
                <EditableBuild prod={prod} />
            )}
        </div>
    )
}

export default Builds