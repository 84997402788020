import React, {useState, useEffect} from 'react' 
import axios from 'axios'
import { connect } from 'react-redux'
import '../styles/editablebuild.css'

const EditableBuild = ({ prod, products: { presetData } }) => {

    const [prodInfo, setprodInfo] = useState(null)
    const [prodData, setprodData] = useState(null)
    const [subtotal, setsubtotal] = useState(0)
    const [kurototal, setkurototal] = useState(0)
    const [taxableamount, settaxableamount] = useState(0)
    const [gst, setgst] = useState(0)
    const [compprices, setcompprices] = useState({
        "cpu": 0,
        "mob": 0,
        "ram": 0,
        "gpu": 0,
        "ssd": 0,
        "hdd": 0,
        "psu": 0,
        "tower": 0,
        "fans": 0,
        "cooler": 0,
        "os": 0,
        "wifi" : 0,
        "build_fees": 0,
        "shp_fees": 0
    })
    const [initFlag, setinitFlag] = useState(true)

    useEffect(() => {
        setprodInfo(prod)
        setprodData(prod)
    }, [prod])

    useEffect(() => {
        if (presetData!== null && initFlag && prodInfo !== null) {
            let tempcompprices = {}
            for (const [pstype, presetid] of Object.entries(prodInfo.presets)) {
                if (pstype !== "margin" && presetid !== "") {
                    const pslist = presetData.filter(ps  => ps.type === pstype)[0]["list"]
                    const price = pslist.filter(item => item.presetid === presetid)[0]["price"]
                    tempcompprices[pstype] = price
                }
            }
            setcompprices(tempcompprices)
            updatesubtotal(tempcompprices)
            setinitFlag(false)
        }
    }, [prodInfo])

    useEffect(() => {
        if (prod !== null) {
            if (prod.price.kuro_on) {setkurototal(prod.price.kuro_on)}
        }
    }, [prod])

    useEffect(() => {
        settaxableamount(Math.floor(kurototal / 1.18))
        setgst(Math.ceil(0.18 * kurototal / 1.18))
    }, [kurototal])

    const updateTotal = (subtot, marginid) => {
        let margin = presetData.filter(ps  => ps.type === "margin")[0]["list"].filter(item => item.presetid === marginid)[0]["kmar"]
        let tempprodinfo = {...prodInfo}
        tempprodinfo["price"]["kuro_on"] = Math.round(subtot * (1 + (margin/100)))
        setprodInfo(tempprodinfo)
    }

    const updatesubtotal = (comps) => {
        let subtot = 0
        for (const [pstype, price] of Object.entries(comps)) {
            subtot += Number(price)
        }
        setsubtotal(subtot)
        updateTotal(subtot, prodInfo["presets"]["margin"])
    }

    const updateBuild = (e, key) => {
        let tempprodinfo = {...prodInfo}
        if (key === "kuro_on") {
            tempprodinfo["price"]["kuro_on"] = e.target.value
        } else {
            tempprodinfo[key] = e.target.value
        }
        setprodInfo(tempprodinfo)
    }

    const changepresets = (e, key) => {
        let tempprodinfo = {...prodInfo}
        let tempcompprices = {...compprices}
        tempprodinfo["presets"][key] = e.target.value
        if (key !== "margin" && key !== "warranty") {
            if (e.target.value === "") {
                tempcompprices[key] = ""
            } else {
                tempcompprices[key] = presetData.filter(ps  => ps.type === key)[0]["list"].filter(item => item.presetid === e.target.value)[0]["price"]
            }
        }
        setprodInfo(tempprodinfo)
        setcompprices(tempcompprices)
        updatesubtotal(tempcompprices)
    }

    const submitBuildHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const productid = prodInfo["productid"]
        const title = prodInfo["title"]
        const price = prodInfo["price"]
        const mrp = prodInfo["mrp"]
        const status = prodInfo["status"]
        const presets = prodInfo["presets"]

        const body = JSON.stringify({ productid, title, price, mrp, status, presets })
        axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/prebuilds?phone=9492540571', body, config)
    }

    return (
        <div className="build prod-list">
            {prodInfo !== null &&
            <div className='background'>
                <table className="prod_details" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Productid</td>
                            <td>Title</td>
                            <td>Kuro Price</td>
                            <td>MRP</td>
                            <td>Status</td>
                            <td rowSpan="3"><button id="submit" onClick={submitBuildHandler}>Update Build</button></td>
                        </tr>
                        {prodData !== null &&
                        <tr>
                            <td rowSpan="2"><a href={'https://kurogaming.com/b/' + prodData.prod_url} target='_blank' rel='noreferrer'>{prodData.productid}</a></td>
                            <td>{prodData.title}</td>
                            <td>{prodData.price.kuro_on}</td>
                            <td>{prodData.mrp}</td>
                            <td>{prodData.status}</td>
                        </tr>
                        }
                        <tr>
                            <td><textarea className="title" onChange={(e) => updateBuild(e, "title")} value={prodInfo.title} /></td>
                            <td>{prodInfo.price.kuro_on}</td>
                            <td><input type="number" onChange={(e) => updateBuild(e, "mrp")} value={prodInfo.mrp} /></td>
                            <td>
                                <select onChange={(e) => updateBuild(e, "status")} value={prodInfo.status}>
                                    <option value="In Stock">In Stock</option>
                                    <option value="Currently Unavailable">Currently Unavailable</option>
                                    <option value="Call for Price">Call for Price</option>
                                    <option value="Call for Availability">Call for Availability</option>
                                    <option value="Check Availability">Check Availability</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="inventory" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>Processor</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "cpu")} value={prodInfo.presets.cpu}>
                                {presetData !== null && presetData.filter(ps => ps.type === "cpu")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.cpu}</td>
                            <td>Power Supply Unit</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "psu")} value={prodInfo.presets.psu}>
                                {presetData !== null && presetData.filter(ps => ps.type === "psu")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.psu}</td>
                            <td>Case</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "tower")} value={prodInfo.presets.tower}>
                                {presetData !== null && presetData.filter(ps => ps.type === "tower")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.tower}</td>
                        </tr>
                        <tr>
                            <td>Motherboard</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "mob")} value={prodInfo.presets.mob}>
                                {presetData !== null && presetData.filter(ps => ps.type === "mob")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.mob} </td>
                            <td>SSD</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "ssd")} value={prodInfo.presets.ssd}>
                                {presetData !== null && presetData.filter(ps => ps.type === "ssd")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.ssd} </td>
                            <td>Fans</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "fans")} value={prodInfo.presets.fans}>
                                <option value = "" selected>Select Fans</option>
                                {presetData !== null && presetData.filter(ps => ps.type === "fans")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.fans}</td>
                        </tr>
                        <tr>
                            <td>Graphics Card</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "gpu")} value={prodInfo.presets.gpu}>
                                {presetData !== null && presetData.filter(ps => ps.type === "gpu")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.gpu}</td>
                            <td>HDD</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "hdd")} value={prodInfo.presets.hdd}>
                                <option value = "" selected>Select HDD</option>
                                {presetData !== null && presetData.filter(ps => ps.type === "hdd")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.hdd} </td>
                            <td>WiFi</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "wifi")} value={prodInfo.presets.wifi}>
                                {presetData !== null && presetData.filter(ps => ps.type === "wifi")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.wifi}</td>
                        </tr>
                        <tr>
                            <td>RAM</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "ram")} value={prodInfo.presets.ram}>
                                {presetData !== null && presetData.filter(ps => ps.type === "ram")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.ram}</td>
                            <td>Cooler</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "cooler")} value={prodInfo.presets.cooler}>
                                {presetData !== null && presetData.filter(ps => ps.type === "cooler")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.cooler} </td>
                            <td>Operating System</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "os")} value={prodInfo.presets.os}>
                                {presetData !== null && presetData.filter(ps => ps.type === "os")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.os} </td>
                        </tr>
                        <tr>
                            <td>Build Fees</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "build_fees")} value={prodInfo.presets.build_fees}>
                                {presetData !== null && presetData.filter(ps => ps.type === "build_fees")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.build_fees} </td>
                            <td>Shipping Fees</td>
                            <td colSpan="2">
                            <select onChange={(e) => changepresets(e, "shp_fees")} value={prodInfo.presets.shp_fees}>
                                {presetData !== null && presetData.filter(ps => ps.type === "shp_fees")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.shp_fees} </td>
                            <td>Warranty</td>
                            <td colSpan="2" >
                            <select onChange={(e) => changepresets(e, "warranty")} value={prodInfo.presets.warranty}>
                                {presetData !== null && presetData.filter(ps => ps.type === "warranty")[0]["list"].map((item, i) =>
                                <option key={i} value={item.presetid}>{item.title}</option>
                                )}
                            </select>
                            </td>
                            <td>{compprices.warranty} </td>
                        </tr>
                    </tbody>
                </table>
                <table className="inventory" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td colSpan="2">Current KM(%)</td>
                            <td colSpan="2">
                            {presetData !== null && presetData.filter(ps => ps.type === "margin")[0]["list"].filter(item => item.presetid === prodData.presets.margin)[0].kmar}</td>
                            <td colSpan="2">Taxable Amount</td>
                            <td colSpan="2">{taxableamount}</td>
                        </tr>
                        <tr>
                            <td>KM(%)</td>
                            <td>
                                <select onChange={(e) => changepresets(e, "margin")} value={prodInfo.presets.margin}>
                                    {presetData !== null && presetData.filter(ps => ps.type === "margin")[0]["list"].map((item, i) =>
                                    <option key={i} value={item.presetid}>{item.kmar}</option>
                                    )}
                                </select>
                            </td>
                            <td>KM(Rs)</td>
                            <td>{prodInfo.price.kuro_on - subtotal}</td>
                            <td colSpan="2">Total Tax</td>
                            <td colSpan="2">{gst}</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Sub Total</td>
                            <td colSpan="2">{subtotal}</td>
                            <td colSpan="2">Build Total</td>
                            <td colSpan="2">{prodInfo.price.kuro_on}</td>
                        </tr>
                        <tr>
                        </tr>
                    </tbody>
                </table>
            </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products
})

export default connect(mapStateToProps)(EditableBuild)